// @import 'base';

nav {
  background-color: #222;
  padding: 5px 20px;
  color: white;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;

    a {
      color: white;
      text-decoration: none;
      justify-content: center;
    }
    
    li {
      padding: 15px 5px;
      font-size: 16px;

      .logged-in {
        display:none;
      }
      a:hover {
        background-color: $color3_d;
      }
    }
  }


  .bars {
    background-color: #999;
    display: inline-block;
    height: 2px;
    position: relative;
    width: 18px;
  }

  .bars::before,
  .bars::after {
    background-color: #999;
    content: "";
    display: inline-block;
    height: 2px;
    position: absolute;
    width: 18px;
  }

  .bars::before {
    top: -5px;
  }

  .bars::after {
    top: -10px;
  }

  .toggle {
    cursor: pointer;
    order: 1;
    width: 50%;
    display: block;
    justify-content: left;
  }
  
  .item {
    width: 100%;
    text-align: center;
    order: 3;
    display: none;
  }
  
  .item.active {
    display: block;
  }

  .buttonS {
    order: 2;
  }

  
  @media all and (min-width: 550px) {
      .item {
        display: block;
        width: auto;
        order: 2;
      }
      
      .toggle {
        display: none;
      }
    
      .menu {
        justify-content: space-between;
      }
    
      .item.buttonS {
        order: 3;
        width: auto;
      }
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  justify-content: center;
  display: flex;
}

body {
  width: 100%;
  max-width: 800px;
  padding: 5px;
  font: 500 100% Helvetica, sans-serif;
}

header {
  background-color: #fff;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  display: grid;
}

.hidden {
  display: none;
}

.buttonS {
  color: #fff;
  width: auto;
  cursor: pointer;
  border-radius: 50em;
  align-items: center;
  padding: 7px 15px;
}

.buttonL {
  color: #fff;
  text-align: center;
  border: none;
  border-radius: .8em;
  margin: 5px;
  padding: 15px 32px;
  font-size: 1.5em;
  text-decoration: none;
}

.btn-green {
  background-color: #2b817f;
}

.btn-red {
  background-color: #9a474c;
}

.warn-zone {
  color: #000;
  text-align: center;
  background-color: #ffff96;
  border: 1px solid #000;
  font-weight: 700;
}

.ok-zone {
  color: #000;
  text-align: center;
  background-color: #38a3a1;
  border: 1px solid #000;
  font-weight: 700;
}

.flexrow {
  align-items: center;
  display: flex;
}

.flexcol {
  flex-flow: column;
  align-items: center;
  display: flex;
}

a {
  align-items: center;
  display: flex;
}

.monotype {
  font-family: Courier New, Courier, monospace;
}

.WhiteOnColor2L {
  color: #fff;
  width: 100%;
  text-align: center;
  background-color: #bb5158;
  border-radius: .3em;
  padding: .5em 0;
}

.LGrayOnGray {
  color: #b9b9b9;
  width: 100%;
  text-align: center;
  background-color: #868686;
  border-radius: .3em;
  padding: .5em 0;
}

.center {
  text-align: center;
  justify-content: center;
}

input {
  width: 100%;
  padding: .5em 5px;
}

hr {
  clear: both;
  width: 100%;
  height: 1px;
  background-color: #38a3a1;
  border: 0;
  display: block;
}

input {
  max-width: 20em;
}

.note {
  font-size: 1em;
  font-style: italic;
}

.title {
  background-color: #b9b9b9;
  border-radius: 10px;
}

nav {
  color: #fff;
  background-color: #222;
  padding: 5px 20px;
}

nav ul {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  display: flex;
}

nav ul a {
  color: #fff;
  justify-content: center;
  text-decoration: none;
}

nav ul li {
  padding: 15px 5px;
  font-size: 16px;
}

nav ul li .logged-in {
  display: none;
}

nav ul li a:hover {
  background-color: #555;
}

nav .bars {
  height: 2px;
  width: 18px;
  background-color: #999;
  display: inline-block;
  position: relative;
}

nav .bars:before, nav .bars:after {
  content: "";
  height: 2px;
  width: 18px;
  background-color: #999;
  display: inline-block;
  position: absolute;
}

nav .bars:before {
  top: -5px;
}

nav .bars:after {
  top: -10px;
}

nav .toggle {
  cursor: pointer;
  width: 50%;
  order: 1;
  justify-content: left;
  display: block;
}

nav .item {
  width: 100%;
  text-align: center;
  order: 3;
  display: none;
}

nav .item.active {
  display: block;
}

nav .buttonS {
  order: 2;
}

@media (min-width: 550px) {
  nav .item {
    width: auto;
    order: 2;
    display: block;
  }

  nav .toggle {
    display: none;
  }

  nav .menu {
    justify-content: space-between;
  }

  nav .item.buttonS {
    width: auto;
    order: 3;
  }
}

.form_par {
  flex-direction: column;
  display: flex;
}

.form_par button, .form_par input {
  width: 10em;
  align-self: center;
}

footer {
  margin: 20px 0;
}

footer p {
  padding: 5px 15px;
  font-size: small;
}

footer a {
  color: #fff;
  display: inline;
}

#triggerAlert .sliders {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#triggerAlert .sliderBox {
  width: 80%;
  height: 3em;
  background-color: #1c5352;
  border-radius: 3em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

#triggerAlert .armed {
  background-color: #bb5158;
}

#triggerAlert .sliderText {
  color: #fff;
}

#triggerAlert .slider {
  width: 3em;
  height: 3em;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #38a3a1;
  border: 3px solid #2b817f;
  border-radius: 53%;
  align-self: flex-start;
}

#triggerAlert .slider :active {
  background-color: #9cbcff;
}

#triggerAlert .slider :hover {
  cursor: pointer;
}

#georef {
  width: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  justify-content: center;
  display: flex;
}

body {
  width: 100%;
  max-width: 800px;
  padding: 5px;
  font: 500 100% Helvetica, sans-serif;
}

header {
  background-color: #fff;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  display: grid;
}

.hidden {
  display: none;
}

.buttonS {
  color: #fff;
  width: auto;
  cursor: pointer;
  border-radius: 50em;
  align-items: center;
  padding: 7px 15px;
}

.buttonL {
  color: #fff;
  text-align: center;
  border: none;
  border-radius: .8em;
  margin: 5px;
  padding: 15px 32px;
  font-size: 1.5em;
  text-decoration: none;
}

.btn-green {
  background-color: #2b817f;
}

.btn-red {
  background-color: #9a474c;
}

.warn-zone {
  color: #000;
  text-align: center;
  background-color: #ffff96;
  border: 1px solid #000;
  font-weight: 700;
}

.ok-zone {
  color: #000;
  text-align: center;
  background-color: #38a3a1;
  border: 1px solid #000;
  font-weight: 700;
}

.flexrow {
  align-items: center;
  display: flex;
}

.flexcol {
  flex-flow: column;
  align-items: center;
  display: flex;
}

a {
  align-items: center;
  display: flex;
}

.monotype {
  font-family: Courier New, Courier, monospace;
}

.WhiteOnColor2L {
  color: #fff;
  width: 100%;
  text-align: center;
  background-color: #bb5158;
  border-radius: .3em;
  padding: .5em 0;
}

.LGrayOnGray {
  color: #b9b9b9;
  width: 100%;
  text-align: center;
  background-color: #868686;
  border-radius: .3em;
  padding: .5em 0;
}

.center {
  text-align: center;
  justify-content: center;
}

input {
  width: 100%;
  padding: .5em 5px;
}

hr {
  clear: both;
  width: 100%;
  height: 1px;
  background-color: #38a3a1;
  border: 0;
  display: block;
}

input {
  max-width: 20em;
}

.note {
  font-size: 1em;
  font-style: italic;
}

.title {
  background-color: #b9b9b9;
  border-radius: 10px;
}

main {
  background-color: #fff;
}

#g_id_onload {
  padding: .5em 0;
}

.container {
  width: 70%;
  margin: .5em;
}

p {
  text-align: justify;
  padding: 10px 15px;
  font-size: 1.2em;
}

input {
  text-align: center;
  font-size: 1.5em;
}

h1 {
  justify-content: center;
  margin: 12px 0;
  font-size: 2em;
}

h2 {
  justify-content: center;
  padding: 10px 15px;
  font-size: 1.7em;
}

h3 {
  justify-content: center;
  padding: 10px 15px;
  font-size: 1.4em;
  font-style: italic;
}

.green-btn {
  background-color: #2b817f;
}

.red-btn {
  background-color: #9a474c;
}

.lgrey-txt {
  color: #b9b9b9;
}

.dgrey-txt {
  color: #868686;
}

footer {
  color: #fff;
  background-color: #000;
}

.right {
  text-align: right;
  float: right;
}

.hcenter {
  text-align: center;
}

.text_mid {
  font-size: 1.5em;
}

.combobox {
  color: #b9b9b9;
  background-color: #1c5352;
  border-radius: .5em;
  justify-content: center;
  padding: 10px 15px;
  font-size: .9em;
  font-style: italic;
  font-weight: bold;
}

/*# sourceMappingURL=index.f4f4d9aa.css.map */

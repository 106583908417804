// @import 'base';

footer {
  margin: 20px 0px;
  
  p {
    font-size: small;
    padding: 5px 15px;
  }

  a {
    color: white;
    display: inline;
  }
}
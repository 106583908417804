// @import 'base';

.form_par {
  display: flex;
  flex-direction: column;

  button, input {
    align-self: center;
    width: 10em;
  }
}
@import 'base';

main {
  background-color: white;
}

#g_id_onload {
  padding: 0.5em 0;
}

.container {
  margin: 0.5em;
  width: 70%;
}
@import 'base';
@import 'nav';
@import 'subscriptions';
@import 'footer';
@import 'alarm';
@import 'main';


p {
  font-size: 1.2em;
  text-align: justify;
  padding: 10px 15px;
}

input {
  font-size: 1.5em;
  text-align: center;
}

h1 {
  font-size: 2em;
  margin: 12px 0;
  justify-content: center;
}

h2 {
  font-size: 1.7em;
  justify-content: center;
  padding: 10px 15px;
}

h3 {
  font-size: 1.4em;
  justify-content: center;
  padding: 10px 15px;
  font-style: italic;
}

.green-btn {
  background-color: $color1_n;
}

.red-btn {
  background-color: $color2_n;
}

.lgrey-txt {
  color: $color3_l;
}

.dgrey-txt {
  color: $color3_n;
}

footer {
  background-color: black;
  color: white;
}

.right {
  text-align: right;
  float:right;
}

.hcenter {
  text-align: center;
}

.text_mid {
  font-size: 1.5em;
}

.combobox {
  font-size: 0.9em;
  font-weight: bold;
  justify-content: center;
  padding: 10px 15px;
  font-style: italic;
  background-color: $color1_d;
  color: $color3_l;
  border-radius: 0.5em;
}
// @import 'base';

#triggerAlert {
  .sliders {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sliderBox {
    width: 80%;
    // max-width: 800px;
    height: 3em;
    background-color: $color1_d;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 3em;
    // touch-action: none;
    align-items: center;
    justify-content: center;
  }

    .armed {
      background-color: $color2_l;
    }
  .sliderText {
    // display:block;
    color: white;
    // text-align: center;
    // justify-self: center;
  }

  .slider {
    width: 3em;
    height: 3em;
    background-color: $color1_l;
    border: 3px solid $color1_n;
    border-radius: 53%;
    touch-action: none;
    user-select: none;
    align-self: flex-start;

    :active {
      background-color: rgb(156, 188, 255);
    }

    :hover {
      cursor: pointer;
    }
  }
}

#georef {
  width: 100%;
}
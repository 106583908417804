$color1_l: #38a3a1; // green (light)
$color1_n: #2b817f; // green (normal)
$color1_d: #1c5352; // green (dark)
$color2_l: #bb5158; // red for buttons
$color2_n: #9a474c; // red for buttons
$color2_d: #79373b; // red for buttons
$color3_l: #b9b9b9; // grey for text
$color3_n: #868686; // grey for text
$color3_d: #555555; // grey for text

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  display: flex;
  justify-content: center;
}

body {
  padding: 5px;
  font: 500 100% "Helvetica", sans-serif;
  width: 100%;
  max-width: 800px;
}

header {
  display: grid;
  background-color: white;
  grid-template-columns: repeat(3, auto);
  align-items: center;
}

.hidden {
  display: none;
}

.buttonS {
  padding: 7px 15px;
  align-items: center;
  // display: inline-flex;
  color: white;
  border-radius: 50em;
  width: auto;
  cursor: pointer;
}

.buttonL {
  border: none;
  margin: 5px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 1.5em;
  border-radius: 0.8em;
}

.btn-green {
  background-color: $color1_n;
}

.btn-red {
  background-color: $color2_n;
}

.warn-zone {
  background-color: rgb(255, 255, 150);
  color: black;
  font-weight: 700;
  text-align: center;
  border: black solid 1px;
}

.ok-zone {
  background-color: $color1_l;
  color: black;
  font-weight: 700;
  text-align: center;
  border: black solid 1px;
}

.flexrow {
  display: flex;
  align-items: center;
}

.flexcol {
  display: flex;
  flex-flow: column;
  align-items: center;
}

a {
  display: flex;
  align-items: center;
}

.monotype {
  font-family: 'Courier New', Courier, monospace;
}

.WhiteOnColor2L {
  background-color: $color2_l;
  color: white;
  border-radius: 0.3em;;
  padding: 0.5em 0;
  width: 100%;
  text-align: center;
}

.LGrayOnGray {
  background-color: $color3_n;
  color: $color3_l;
  border-radius: 0.3em;;
  padding: 0.5em 0;
  width: 100%;
  text-align: center;
}

.center {
  text-align: center;
  justify-content: center;
}

input {
  padding: 0.5em 5px;
  width: 100%;
}

hr {
  border: 0;
  clear:both;
  display:block;
  width: 100%;               
  background-color: $color1_l;
  height: 1px;
}

input {
  max-width: 20em;
}

.note {
  font-size: 1em;
  font-style: italic;
}

.title {
  background-color: $color3_l;
  border-radius: 10px;
}